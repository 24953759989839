import { enqueueSnackbar } from 'notistack'

import React from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import client from '~/client'
import useUserProfile from '~/routes/Admin/Profile/useUserProfile'
import shouldOnboard from '~/routes/Onboarding/shouldOnboard'

export default function useResumeOnboarding() {
  const navigate = useNavigate()
  const { data: user } = useUserProfile()
  const hasRedirectedRef = React.useRef(false)
  const match = useMatch('/onboarding/*')

  React.useEffect(() => {
    const userInfo = client.getUserInfo()

    if (match) {
      hasRedirectedRef.current = true
      return
    }

    if (!userInfo?.role || !user?.email || hasRedirectedRef.current === true)
      return

    if (shouldOnboard(user)) {
      enqueueSnackbar('Please complete your onboarding', { variant: 'info' })
      hasRedirectedRef.current = true
      navigate('/onboarding?step=user-details', {
        state: { user, username: user.email },
      })
    }
  }, [user, match])
}
