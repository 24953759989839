import isDev from '~/config/isDev'
import StackClient from './StackClient'
import { enqueueSnackbar } from 'notistack'
import * as Sentry from '@sentry/react'

const client = new StackClient({
  baseUrl: import.meta.env.VITE_API_DOMAIN,
  onApiError: e => {
    Sentry.withScope(scope => {
      scope.setExtra('request', e.request)
      Sentry.captureException(e)
    })
    console.error('%cSENTRY CAPTURE', 'color: orange', e.toString(), e.request)
  },
  onError: e => {
    if (!window.navigator.onLine) {
      enqueueSnackbar('No internet connection')
      return
    }
    if (isDev())
      enqueueSnackbar(e?.toString() || 'Unspecified error', {
        variant: 'error',
      })
  },
  onInValidToken: () => {
    enqueueSnackbar('Session expired, please sign in again', {
      variant: 'error',
    })
    window.location = '/sign-in'
  },
})

export default client
