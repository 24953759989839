import * as Sentry from '@sentry/react'
import isDev from '~/config/isDev'

const envs = {
  'salesapp.unicosystem.com': 'production',
  'stage-salesapp.unicosystem.com': 'staging',
}
// !isDev() &&

// const productionIntegrations = isDev() ?

Sentry.init({
  attachStacktrace: isDev(),
  environment: envs[window.location.hostname] || 'dev',
  dsn: 'https://2cd622c6604d84c5b5c1a0f37baa403a@o4506592816267264.ingest.sentry.io/4506592818888704',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        // 'localhost',
        // /^https:\/\/yourserver\.io\/api/,
        /^https:\/\/stage-salesapp\.unicosystem\.com/,
        /^http:\/\/salesapp\.unicosystem\.com/,
      ],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
    new Sentry.Feedback({
      // Additional SDK configuration goes in here
      // colorScheme: 'light',
      // showBranding: false,
      autoInject: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
