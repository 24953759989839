export default class ApiError extends Error {
  constructor(message, request) {
    super(message)
    this.name = 'ApiError'
    this.request = request

    if (Error.captureStackTrace) Error.captureStackTrace(this, ApiError)
  }

  toString() {
    return `${this.name}: ${this.message} calling ${this.request?.url || 'API'}`
  }
}

// // Usage example
// try {
//   // Example of throwing the error with a message and URL
//   throw new CORSError("CORS policy prevented the request.", "https://example.com/api");
// } catch (error) {
//   console.error(error.toString());
//   // Output: CORSError: CORS policy prevented the request. (Request URL: https://example.com/api)
// }
